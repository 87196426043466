<template>
  <div>
    <h4 class="mb-5">{{$t('To')}}: {{warehousesDic[bin.to_warehouse]}}</h4>
    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      :max-items="maximumItems"
      pagination
      :data="orders"
      :tableLoader="tableLoader"
      :hideHeader="true"
    >

      <template slot="thead">
        <shipblu-th>{{$t('Tracking No.')}}</shipblu-th>
        <shipblu-th>{{$t('Package ID')}}</shipblu-th>
        <shipblu-th>{{$t('Merchant Name')}}</shipblu-th>
        <shipblu-th>{{$t('Customer Name')}}</shipblu-th>
        <shipblu-th class="step-12 order-1 flex justify-center" v-if="$store.state.AppActiveUser.userRole === 'manager'">
          <feather-icon icon="ChevronDownIcon" svgClasses="h-5 w-5"/>
        </shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td class="sm:hidden block col-span-2 p-0 order-3">
            <!-- line -->
            <p style="background-color: #DCDCDC;height: 1px;"></p>
          </shipblu-td>

          <shipblu-td :data="data[indextr].tracking_number" class="text-left sm:order-3 order-16 sm:pointer-events-none">
            <div class="flex sm:static absolute top-3 gap-2 items-start justify-start transform-50" v-on:click.stop>
              <p class="sm:text-base text-lg flex gap-1 items-start justify-center">
                {{ data[indextr].order.tracking_number }}
                <span @click="copied(data[indextr].order.tracking_number)" class="material-icons-round text-grey hover:text-primary text-xl cursor-pointer sm:hidden block">content_copy</span>
              </p>
            </div>
          </shipblu-td> 

          <shipblu-td class="order-3" :data="data[indextr].package">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Package ID')}}</p>
            <p class="sm:text-base text-sm">
              {{ Array(String(data[indextr].package.id).length - 1).join("*") + String(data[indextr].package.id).slice(-2) }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-4" :data="data[indextr].merchatName">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Merchant Name')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].order.merchant.name }}
            </p>
          </shipblu-td>

          <shipblu-td class="order-5" :data="data[indextr].customerName">
            <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Customer Name')}}</p>
            <p class="sm:text-base text-sm">
              {{ data[indextr].order.customer.full_name }}
            </p>
          </shipblu-td>


          <shipblu-td class="order-2" style="vertical-align: middle;" v-if="$store.state.AppActiveUser.userRole === 'manager'">
            <div class="md:text-center text-right" @click="removeAttribute($event)">
              <vs-dropdown
                vs-trigger-click
                @click="selected = []"
                class="dd-actions cursor-pointer"
              >
                <span class="material-symbols-outlined text-2xl">more_horiz</span> 
                <vs-dropdown-menu style="width: 190px">
                  <vs-dropdown-item
                    @click="confirmRemove(data[indextr])"
                  >
                    <span class="flex items-center">
                      <feather-icon
                        icon="XCircleIcon"
                        svgClasses="h-4 w-4"
                        class="mr-2"
                      />
                      <span>{{$t('Remove')}}</span>
                    </span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </div>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import AXIOS from '../../http/axios/index.js'
import i18nData from '../../i18n/i18nData.js'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
      },
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      orders: [],
      selected: [],
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      package: {},
      bin: {},
      tableLoader: false,
      warehousesDic: {}
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.loadPackages()
    }
  },
  methods: {
    formatWarehouses (warehouses) {
      warehouses.forEach(warehouse => {
        this.warehousesDic[warehouse.id] = warehouse.code
      })
    },
    loadBin () {
      sendRequest(false, false, this, `api/v1/bins/${this.$route.params.unitID}/`, 'get', null, true, 
        (response) => {
          this.bin = response.data
        }
      )
    },
    loadPackages () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/warehousing/bins/${this.$route.params.unitID}/packages/?offset=${this.offset}&limit=${this.maximumItems}`, 'get', null, true, 
        (response) => {
          this.orders = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    confirmRemove (data) {
      this.package = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: 'Are you sure to remove this package?',
        accept: this.removePackage
      })
    },
    removePackage () {
      const packages = this.package.package.id
      AXIOS.delete(`api/v1/warehousing/bins/${this.$route.params.unitID}/packages/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`
        },
        data: {
          packages: [packages]
        }
      })
        .then(() => {
          this.$vs.notify({
            color:'success',
            title:i18nData[this.$i18n.locale]['Success'],
            text:i18nData[this.$i18n.locale]['Package'] + i18nData[this.$i18n.locale][' has been deleted successfully!'],
            position: 'top-center'
          })
          this.loadPackages()
          this.$vs.loading.close()
        })
        .catch((error) => {
          common.notifyResponseError(this, error)
          this.$vs.loading.close()
        })
    },
    async copied (data) {
      try {
        await navigator.clipboard.writeText(data)
      } catch ($e) {
        this.$vs.notify({
          color:'dark',
          title:i18nData[this.$i18n.locale]['cannot copy!'],
          position: 'bottom-center'
        })
      }
    }
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    ShipbluPagination
  },
  created () {
    common.manageLoadWarehouses(this.formatWarehouses, this)
    this.loadPackages()
    this.loadBin()
  }
}
</script>

<style scoped>
.vs-dialog {
  max-width: 530px;
}
</style>
<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
#order-label-print{
  display: none;
  visibility: hidden;
}
.con-pagination-table{
  display: none;
}
</style>
